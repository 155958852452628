import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import userReducer from "./userSlice";
import systemCompanyReducer from "./systemCompanySlice";
import clientCompanyReducer from "./clientCompanySlice";
import agentCompanyReducer from "./agentCompanySlice";
import campaignMasterDataReducer from "./campaignMasterDataSlice";
import campaignReducer from "./campaignSlice";
import companyEventReducer from "./companyEventSlice";
import contentFileReducer from "./contentFileSlice";
import * as effectReducer from "../reducers/index";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  systemCompany: systemCompanyReducer,
  clientCompany: clientCompanyReducer,
  agentCompany: agentCompanyReducer,
  campaignMasterData: campaignMasterDataReducer,
  campaign: campaignReducer,
  companyEvent: companyEventReducer,
  contentFile: contentFileReducer,
  ...effectReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
