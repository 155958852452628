// キャンペーン編集時に利用する各種Masterデータを管理
// リードオンリーなデータしか扱わない
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  CampaignPurpose,
  CampaignPurposeApi,
  CampaignType,
  CampaignTypeApi,
  ChildIndustry,
  ChildIndustryApi,
  Industry,
  IndustryApi,
  KPIType,
  KpiTypeApi,
  TargetType,
  TargetTypeApi,
  MagazineMaster,
  MagazineMasterApi,
  RadioTimeArea,
  RadiotimeAreaApi,
  RadioTimeStation,
  RadiotimeStationApi,
  TrafficMasterApi,
  WebMediatypesApi,
  TrafficMaster,
  WebMediaType,
  NewspaperMaster,
  NewspaperMasterApi,
  RadiospotAreaApi,
  RadiospotStationApi,
  RadioSpotStation,
  RadioSpotArea,
  TVTimeArea,
  TvtimeAreaApi,
  TVTimeRatingArea,
  TvtimeRatingareaApi,
  TVTimeRatingTarget,
  TvtimeRatingtargetApi,
  TVTimeStation,
  TvtimeStationApi,
} from "@src/openapi-generator";
import { RootState } from "./rootReducer";
import { AppDispatch } from "../store";
import { autoRefreshAxiosInstance } from "@src/utils";

// Api
function sortComparer<T extends { created_at?: string }>(a: T, b: T): number {
  return a.created_at && b.created_at
    ? a.created_at.localeCompare(b.created_at)
    : 0;
}

// Adapter
// const campaignPurposeAdapter = createEntityAdapter<CampaignPurpose>({sortComparer: (a, b) => (a.created_at && b.created_at) ? a.created_at?.localeCompare(b.created_at) : 0})
const campaignPurposeAdapter = createEntityAdapter<CampaignPurpose>({
  sortComparer: sortComparer,
});
const campaignTypeAdapter = createEntityAdapter<CampaignType>({
  sortComparer: sortComparer,
});
const industryAdapter = createEntityAdapter<Industry>({
  sortComparer: sortComparer,
});
const childIndustryAdapter = createEntityAdapter<ChildIndustry>({
  sortComparer: sortComparer,
});
const kpiTypeAdapter = createEntityAdapter<KPIType>({
  sortComparer: sortComparer,
});
const targetTypeAdapter = createEntityAdapter<TargetType>({
  sortComparer: sortComparer,
});

// State
const campaignPurposeInitialState = campaignPurposeAdapter.getInitialState();
const campaignTypeInitialState = campaignTypeAdapter.getInitialState();
const industryInitialState = industryAdapter.getInitialState();
const childIndustryInitialState = childIndustryAdapter.getInitialState();
const kpiTypeInitialState = kpiTypeAdapter.getInitialState();
const targetTypeInitialState = targetTypeAdapter.getInitialState();

type State = {
  campaignPurpose: typeof campaignPurposeInitialState;
  campaignType: typeof campaignTypeInitialState;
  industry: typeof industryInitialState;
  childIndustry: typeof childIndustryInitialState;
  kpiType: typeof kpiTypeInitialState;
  targetType: typeof targetTypeInitialState;
  isLoading: boolean;
  error: string | null;
};

const initialState: State = {
  campaignPurpose: campaignPurposeInitialState,
  campaignType: campaignTypeInitialState,
  industry: industryInitialState,
  childIndustry: childIndustryInitialState,
  kpiType: kpiTypeInitialState,
  targetType: targetTypeInitialState,
  isLoading: false,
  error: null,
};

// Thunk
type ThunkAPIConfig = {
  state: RootState;
  dispatch: AppDispatch;
  // rejectValue: ,
  // extra: ,
};

export const fetchCampaignPurpose = createAsyncThunk<
  CampaignPurpose[],
  void,
  ThunkAPIConfig
>("campaignPurpose/fetch", async (_arg, _thunkAPI) => {
  const campaignPurposeApi = new CampaignPurposeApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await campaignPurposeApi.campaignPurposeList();
  return response.data;
});

export const fetchCampaignType = createAsyncThunk<
  CampaignType[],
  void,
  ThunkAPIConfig
>("campaignType/fetch", async (_arg, _thunkAPI) => {
  const campaignTypeApi = new CampaignTypeApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await campaignTypeApi.campaignTypeList();
  return response.data;
});

export const fetchIndustry = createAsyncThunk<Industry[], void, ThunkAPIConfig>(
  "industry/fetch",
  async (_arg, _thunkAPI) => {
    const industryApi = new IndustryApi(
      undefined,
      undefined,
      autoRefreshAxiosInstance
    );
    const response = await industryApi.industryList();
    return response.data;
  }
);

export const fetchChildIndustry = createAsyncThunk<
  ChildIndustry[],
  void,
  ThunkAPIConfig
>("childIndustry/fetch", async (_arg, _thunkAPI) => {
  const childIndustryApi = new ChildIndustryApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await childIndustryApi.childIndustryList();
  return response.data;
});

export const fetchKpiType = createAsyncThunk<KPIType[], void, ThunkAPIConfig>(
  "kpiType/fetch",
  async (_arg, _thunkAPI) => {
    const kpiTypeApi = new KpiTypeApi(
      undefined,
      undefined,
      autoRefreshAxiosInstance
    );
    const response = await kpiTypeApi.kpiTypeList();
    return response.data;
  }
);

export const fetchTargetType = createAsyncThunk<
  TargetType[],
  void,
  ThunkAPIConfig
>("targetType/fetch", async (_arg, _thunkAPI) => {
  const targetTypeApi = new TargetTypeApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await targetTypeApi.targetTypeList();
  return response.data;
});

/**
 * magazine master data
 */
export const fetchwebMasterList = createAsyncThunk<
  WebMediaType[],
  void,
  ThunkAPIConfig
>("campaign/webMediaTypes", async (_arg, _thunkAPI) => {
  const webMediaTypeApi = new WebMediatypesApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await webMediaTypeApi.webMediatypesList();
  return response.data;
});

/**
 * magazine master data
 */
export const fetchMagazineMasterList = createAsyncThunk<
  MagazineMaster[],
  void,
  ThunkAPIConfig
>("campaign/magazineMasterList", async (_arg, _thunkAPI) => {
  const magazineApi = new MagazineMasterApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await magazineApi.magazineMasterList();
  return response.data;
});

/**
 * traffic master data
 */
export const fetchTrafficMasterList = createAsyncThunk<
  TrafficMaster[],
  void,
  ThunkAPIConfig
>("campaign/trafficMasterList", async (_arg, _thunkAPI) => {
  const trafficApi = new TrafficMasterApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await trafficApi.trafficMasterList();
  return response.data;
});

/**
 * radioTime master data
 */
export const fetchRadioTimeAreaList = createAsyncThunk<
  RadioTimeArea[],
  void,
  ThunkAPIConfig
>("campaign/radioTimeArea", async (_arg, _thunkAPI) => {
  const radioTimeApi = new RadiotimeAreaApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await radioTimeApi.radiotimeAreaList();
  return response.data;
});

export const fetchRadioTimeStationList = createAsyncThunk<
  RadioTimeStation[],
  void,
  ThunkAPIConfig
>("campaign/radioTimeStation", async (_arg, _thunkAPI) => {
  const radioTimeApi = new RadiotimeStationApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await radioTimeApi.radiotimeStationList();
  return response.data;
});

/**
 * newspaper master data
 */
export const fetchNewsPaperMaster = createAsyncThunk<
  NewspaperMaster[],
  void,
  ThunkAPIConfig
>("campaign/agent_master", async (_arg, _thunkAPI) => {
  const newspaperMasterApi = new NewspaperMasterApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await newspaperMasterApi.newspaperMasterList();
  return response.data;
});

/**
 * radioSpot master data
 */
export const fetchRadioSpotArea = createAsyncThunk<
  RadioSpotArea[],
  void,
  ThunkAPIConfig
>("campaign/radioSpotArea", async (_arg, _thunkAPI) => {
  const radiospotApi = new RadiospotAreaApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await radiospotApi.radiospotAreaList();
  return response.data;
});

export const fetchRadioSpotStation = createAsyncThunk<
  RadioSpotStation[],
  void,
  ThunkAPIConfig
>("campaign/radioSpotStation", async (_arg, _thunkAPI) => {
  const radiospotApi = new RadiospotStationApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await radiospotApi.radiospotStationList();
  return response.data;
});

export const fetchTVTimeArea = createAsyncThunk<
  TVTimeArea[],
  void,
  ThunkAPIConfig
>("campaign/tvTimeArea", async (_arg, _thunkAPI) => {
  const tvtimeAreaApi = new TvtimeAreaApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await tvtimeAreaApi.tvtimeAreaList();
  return response.data;
});

export const fetchTVTimeStation = createAsyncThunk<
  TVTimeStation[],
  void,
  ThunkAPIConfig
>("campaign/tvTimeStation", async (_arg, _thunkAPI) => {
  const tvtimeStationApi = new TvtimeStationApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await tvtimeStationApi.tvtimeStationList();
  return response.data;
});

export const fetchTVTimeRatingArea = createAsyncThunk<
  TVTimeRatingArea[],
  void,
  ThunkAPIConfig
>("campaign/tvTimeRatingArea", async (_arg, _thunkAPI) => {
  const tvtimeRatingareaApi = new TvtimeRatingareaApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await tvtimeRatingareaApi.tvtimeRatingareaList();
  return response.data;
});

export const fetchTVTimeRatingTarget = createAsyncThunk<
  TVTimeRatingTarget[],
  void,
  ThunkAPIConfig
>("campaign/tvTimeRatingTarget", async (_arg, _thunkAPI) => {
  const tvtimeRatingtargetApi = new TvtimeRatingtargetApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await tvtimeRatingtargetApi.tvtimeRatingtargetList();
  return response.data;
});

// Slice
const slice = createSlice({
  name: "campaignMasterData",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchCampaignPurpose
    builder.addCase(fetchCampaignPurpose.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(fetchCampaignPurpose.fulfilled, (state, action) => {
      campaignPurposeAdapter.upsertMany(state.campaignPurpose, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(fetchCampaignPurpose.rejected, (state, action) => {
      state.isLoading = false;
      console.log("rejected");
      console.log(action);
      state.error = "error";
      return state;
    });

    // fetchCampaignType
    builder.addCase(fetchCampaignType.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(fetchCampaignType.fulfilled, (state, action) => {
      campaignTypeAdapter.upsertMany(state.campaignType, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(fetchCampaignType.rejected, (state, action) => {
      state.isLoading = false;
      console.log("rejected");
      console.log(action);
      state.error = "error";
      return state;
    });

    // fetchIndustry
    builder.addCase(fetchIndustry.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(fetchIndustry.fulfilled, (state, action) => {
      industryAdapter.upsertMany(state.industry, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(fetchIndustry.rejected, (state, action) => {
      state.isLoading = false;
      console.log("rejected");
      console.log(action);
      state.error = "error";
      return state;
    });

    // fetchChildIndustry
    builder.addCase(fetchChildIndustry.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(fetchChildIndustry.fulfilled, (state, action) => {
      childIndustryAdapter.upsertMany(state.childIndustry, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(fetchChildIndustry.rejected, (state, action) => {
      state.isLoading = false;
      console.log("rejected");
      console.log(action);
      state.error = "error";
      return state;
    });

    // fetchKpiType
    builder.addCase(fetchKpiType.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(fetchKpiType.fulfilled, (state, action) => {
      kpiTypeAdapter.upsertMany(state.kpiType, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(fetchKpiType.rejected, (state, action) => {
      state.isLoading = false;
      console.log("rejected");
      console.log(action);
      state.error = "error";
      return state;
    });

    // fetchTargetType
    builder.addCase(fetchTargetType.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(fetchTargetType.fulfilled, (state, action) => {
      targetTypeAdapter.upsertMany(state.targetType, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(fetchTargetType.rejected, (state, action) => {
      state.isLoading = false;
      console.log("rejected");
      console.log(action);
      state.error = "error";
      return state;
    });
  },
});

// Reducer
export default slice.reducer;

// Action
// export const {} = agentCompanySlice.actions;

// Selector

export const campaignPurposeSelectors = campaignPurposeAdapter.getSelectors<RootState>(
  (state) => state.campaignMasterData.campaignPurpose
);
export const campaignTypeSelectors = campaignTypeAdapter.getSelectors<RootState>(
  (state) => state.campaignMasterData.campaignType
);
export const industrySelectors = industryAdapter.getSelectors<RootState>(
  (state) => state.campaignMasterData.industry
);
export const childIndustrySelectors = childIndustryAdapter.getSelectors<RootState>(
  (state) => state.campaignMasterData.childIndustry
);
export const kpiTypeSelectors = kpiTypeAdapter.getSelectors<RootState>(
  (state) => state.campaignMasterData.kpiType
);
export const targetTypeSelectors = targetTypeAdapter.getSelectors<RootState>(
  (state) => state.campaignMasterData.targetType
);
