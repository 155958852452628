import { createAction } from "@reduxjs/toolkit";

export const logout = createAction("LOGOUT");
export const clearAll = createAction("CLEAR_ALL");
export const saveFileData = createAction(
  "SAVE_FILE_DATA",
  function prepare(fileData: any[]) {
    return {
      payload: {
        fileData,
      },
    };
  }
);
