import {
  sizeOptions,
  colorOptions,
  sectionOptions,
} from "@src/dataSources/campaign/newspaper";
import { NewspaperMaster } from "@src/openapi-generator";

type TBaseTempData = {
  name: string;
};
export type TNewPaperReducer = {
  newPaperMasterArr: NewspaperMaster[];
  sectionOptions: TBaseTempData[];
  colorOptions: TBaseTempData[];
  sizeOptions: TBaseTempData[];
};
const initialState: TNewPaperReducer = {
  newPaperMasterArr: [],
  sectionOptions,
  colorOptions,
  sizeOptions,
};

class NewspaperMasterData implements NewspaperMaster {
  id?: string = "";
  created_at?: string = "";
  updated_at?: string = "";
  type: string = "";
  company: string = "";
  area: string = "";
  publication_form: string = "";
  circulation: number = 0;
  constructor(params: any) {
    if (params) {
      const { type, company, area, publication_form, circulation } = params;
      this.type = type;
      this.company = company;
      this.area = area;
      this.publication_form = publication_form;
      this.circulation = circulation;
    }
  }
}

export function newPaperReducer(
  state = initialState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case "campaign/agent_master/fulfilled": {
      const newPaperMasterArr = payload?.map(
        (item: any) => new NewspaperMasterData(item)
      );
      return {
        ...state,
        newPaperMasterArr,
      };
    }
    default: {
      return state;
    }
  }
}
