import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { AppDispatch } from "../store";
import { autoRefreshAxiosInstance } from "@src/utils";
import { CompanyEvent, CompanyEventsApi } from "@src/openapi-generator";
import { clearAll } from "../actions";

// Adapter
const companyEventAdapter = createEntityAdapter<CompanyEvent>()

// State
const companyEventInitialState = companyEventAdapter.getInitialState<{
  isLoading: boolean,
  error: string | null
}>({
  isLoading: false,
  error: null
})


// Thunk
type ThunkAPIConfig = {
  state: RootState,
  dispatch: AppDispatch,
  // rejectValue: ,
  // extra: ,
}

type Params = {
  eventType?: "register_client_company" | "register_client_user" | "register_agent_company_payer_client" | "register_agent_company_payer_agent" | "change_payer_to_client" | "change_payer_to_agent" | undefined,
  registerStatus?: "done" | "pending" | "cancel" | "expired" | undefined,
  requestCompanyId?: string | undefined
}

export const fetchCompanyEvents = createAsyncThunk<
  CompanyEvent[],
  Params,
  ThunkAPIConfig
>(
  'companyEvent/fetchAll',
  async (params, _thunkAPI) => {
    const api = new CompanyEventsApi(undefined, undefined, autoRefreshAxiosInstance)
    const response = await api.companyEventsList(
      params.eventType, params.registerStatus, params.requestCompanyId
    )
    return response.data
  }
)

export const cancelCompanyEvent = createAsyncThunk<
  void,
  {id: string},
  ThunkAPIConfig
>(
  'companyEvent/fetchAll',
  async ({id}, _thunkAPI) => {
    const api = new CompanyEventsApi(undefined, undefined, autoRefreshAxiosInstance)
    const response = await api.companyEventsCancelCreate(id)
    return response.data
  }
)

// Slice
const companyEventSlice = createSlice({
  name: 'companyEvent',
  initialState: companyEventInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyEvents.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    builder.addCase(fetchCompanyEvents.fulfilled, (state, action) => {
      companyEventAdapter.upsertMany(state, action.payload)
      state.isLoading = false;
      state.error = null;
      return state;
    })
    builder.addCase(fetchCompanyEvents.rejected, (state, action) => {
      state.isLoading = false;
      console.log('rejected')
      console.log(action)
      state.error = 'error';
      return state;
    })

    builder.addCase(clearAll, (state) => {
      companyEventAdapter.removeAll(state)
      state.isLoading = false;
      state.error = null;
      return state;
    })
  }
})

// Reducer
export default companyEventSlice.reducer;

// Action
// export const {} = companyEventSlice.actions;

// Selector

export const companyEventSelectors = companyEventAdapter.getSelectors<RootState>(
  (state) => state.companyEvent
)

export const pendingRegisterClientCompanyFilterSelector = createSelector(
  [
    (state: RootState) => companyEventSelectors.selectAll(state)
  ], (events) => {
    return events.filter(
      (event) => (event.event_type === 'register_client_company' && event.register_status === 'pending')
    )
  }
)

export const pendingRegisterAgentCompanyFilterSelector = createSelector(
  [
    (state: RootState) => companyEventSelectors.selectAll(state)
  ], (events) => {
    return events.filter(
      (event) => (
        (event.event_type === 'register_agent_company_payer_client' ||
          event.event_type === 'register_agent_company_payer_agent')
        && event.register_status === 'pending')
    )
  }
)