import {
  TokenApi,
  RoleEnum,
  TargetSexEnum,
  CompanyTypeEnum,
  ClientCompanyDetail,
  CodenameEnum,
  ContentFile,
  ContentFilesApi,
  EventTypeEnum,
  RegisterStatusEnum,
  CampaignPermission,
  CampaignApi,
} from "@src/openapi-generator";
import Axios from "axios";
import { AppDispatch, useAppDispatch } from "@src/redux/store";
import {
  agentCompanyDetailSelectors,
  campaignSelectors,
  fetchAgentCompanies,
  fetchAgentCompany,
  fetchCampaign,
  fetchClientCompany,
  fetchSystemCompany,
  RootState,
  selectMyCompany,
  selectMyUser,
} from "@src/redux";
import { useEffect, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { max, min } from "date-fns";

export function verifyToken(token: string) {
  return new TokenApi().tokenVerifyCreate({ token: token });
}

export function getRoleStr(role: RoleEnum) {
  return {
    [RoleEnum.SystemMaster]: "グランドマスター",
    [RoleEnum.SystemAdmin]: "システム管理者",
    [RoleEnum.SystemUser]: "システムユーザー",
    [RoleEnum.ClientOwner]: "代表管理者",
    [RoleEnum.ClientAdmin]: "管理者",
    [RoleEnum.ClientUser]: "ユーザー",
    [RoleEnum.AgentUser]: "代理店ユーザー",
  }[role];
}

export function getTargetSexStr(role: TargetSexEnum) {
  return {
    [TargetSexEnum.Man]: "男性",
    [TargetSexEnum.Woman]: "女性",
    [TargetSexEnum.Both]: "男女両方",
  }[role];
}

export function getPermissionCodenameStr(codename: CodenameEnum): string {
  return {
    [CodenameEnum.AccessCampaign]: "キャンペーンへのアクセス許可",
    [CodenameEnum.ViewOrientationWithCompetition]:
      "コンペ参加代理店を含むオリエンテーションの閲覧",
    [CodenameEnum.ViewOrientationWithoutCompetition]:
      "コンペ参加代理店を除くオリエンテーションの編集",
    [CodenameEnum.ViewConcept]: "キャンペーンコンセプトの閲覧",
    [CodenameEnum.ChangeConcept]: "キャンペーンコンセプトの編集",
    [CodenameEnum.ViewStrategy]: "キャンペーン戦略の閲覧",
    [CodenameEnum.ChangeStrategy]: "キャンペーン戦略の編集",
    [CodenameEnum.ViewKeyVisual]: "キービジュアルの閲覧",
    [CodenameEnum.ChangeKeyVisual]: "キービジュアルの編集",
    [CodenameEnum.ViewPurpose]: "目的評価の閲覧",
    [CodenameEnum.ComparePurpose]: "目的評価の比較",
    [CodenameEnum.ViewMainTarget]: "メインターゲット訴求評価の閲覧",
    [CodenameEnum.CompareMainTarget]: "メインターゲット訴求評価の比較",
    [CodenameEnum.ViewSubTarget]: "サブターゲット訴求評価の閲覧",
    [CodenameEnum.CompareSubTarget]: "サブターゲット訴求評価の比較",
    [CodenameEnum.ViewCost]: "コストおよびその評価の閲覧",
    [CodenameEnum.CompareCost]: "コストおよびその評価の比較",
    [CodenameEnum.ViewMediaCost]: "媒体別費用の閲覧",
    [CodenameEnum.CompareMediaCost]: "媒体別費用の比較",
    [CodenameEnum.ViewKpi]: "KPIおよびその評価の閲覧",
    [CodenameEnum.CompareKpi]: "KPIおよびその評価の比較",
    [CodenameEnum.ViewPromotion]: "プレゼント(消費者還元)キャンペーンの閲覧",
    [CodenameEnum.ChangePromotion]: "プレゼント(消費者還元)キャンペーンの編集",
    [CodenameEnum.ComparePromotion]: "プレゼント(消費者還元)キャンペーンの比較",
    [CodenameEnum.ViewSns]: "SNS拡散実績およびその評価の閲覧",
    [CodenameEnum.CompareSns]: "SNS拡散実績およびその評価の比較",
    [CodenameEnum.ViewPr]: "PR露出実績およびその評価の閲覧",
    [CodenameEnum.ComparePr]: "PR露出実績およびその評価の比較",
    [CodenameEnum.ViewImprovement]: "次回に向けた改善のポイントの閲覧",
    [CodenameEnum.ViewWithReviewTvspot]: "TV Spotの閲覧(評価含む)",
    [CodenameEnum.ViewWithoutReviewTvspot]: "TV Spotの閲覧(評価除く)",
    [CodenameEnum.ChangeTvspot]: "TV Spotの編集",
    [CodenameEnum.ViewWithReviewTvtime]: "TV Timeの閲覧(評価含む)",
    [CodenameEnum.ViewWithoutReviewTvtime]: "TV Timeの閲覧(評価除く)",
    [CodenameEnum.ChangeTvtime]: "TV Timeの編集",
    [CodenameEnum.ViewWithReviewRadiospot]: "ラジオ Spotの閲覧(評価含む)",
    [CodenameEnum.ViewWithoutReviewRadiospot]: "ラジオ Spotの閲覧(評価除く)",
    [CodenameEnum.ChangeRadiospot]: "ラジオ Spotの編集",
    [CodenameEnum.ViewWithReviewRadiotime]: "ラジオ Timeの閲覧(評価含む)",
    [CodenameEnum.ViewWithoutReviewRadiotime]: "ラジオ Timeの閲覧(評価除く)",
    [CodenameEnum.ChangeRadiotime]: "ラジオ Timeの編集",
    [CodenameEnum.ViewWithReviewNewspaper]: "新聞の閲覧(評価含む)",
    [CodenameEnum.ViewWithoutReviewNewspaper]: "新聞の閲覧(評価除く)",
    [CodenameEnum.ChangeNewspaper]: "新聞の編集",
    [CodenameEnum.ViewWithReviewMagazine]: "雑誌の閲覧(評価含む)",
    [CodenameEnum.ViewWithoutReviewMagazine]: "雑誌の閲覧(評価除く)",
    [CodenameEnum.ChangeMagazine]: "雑誌の編集",
    [CodenameEnum.ViewWithReviewTraffic]: "交通の閲覧(評価含む)",
    [CodenameEnum.ViewWithoutReviewTraffic]: "交通の閲覧(評価除く)",
    [CodenameEnum.ChangeTraffic]: "交通の編集",
    [CodenameEnum.ViewWithReviewWeb]: "Webの閲覧(評価含む)",
    [CodenameEnum.ViewWithoutReviewWeb]: "Webの閲覧(評価除く)",
    [CodenameEnum.ChangeWeb]: "Webの編集",
    [CodenameEnum.ViewWithReviewTopic]: "話題化の編集",
  }[codename];
}

export function getEventTypeStr(eventType: EventTypeEnum) {
  return {
    [EventTypeEnum.RegisterClientCompany]: "依頼主企業登録",
    [EventTypeEnum.RegisterClientUser]: "依頼主ユーザー登録",
    [EventTypeEnum.RegisterAgentCompanyPayerClient]: "代理店登録(依頼主払い)",
    [EventTypeEnum.RegisterAgentCompanyPayerAgent]: "代理店登録(代理店払い)",
    [EventTypeEnum.RegisterAgentUser]: "代理店ユーザー登録",
    [EventTypeEnum.ChangePayerToAgent]: "請求先変更(依頼主→代理店)",
    [EventTypeEnum.ChangePayerToClient]: "請求先変更(代理店→依頼主)",
  }[eventType];
}

export function getRegisterStatusStr(registerStatus: RegisterStatusEnum) {
  return {
    [RegisterStatusEnum.Pending]: "保留中",
    [RegisterStatusEnum.Done]: "完了",
    [RegisterStatusEnum.Cancel]: "キャンセル",
    [RegisterStatusEnum.Expired]: "期限切れ",
  }[registerStatus];
}

export function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function useMounted() {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => setMounted(true), []);
  return mounted;
}

export function makeEmailLink(to: string, subject: string, body: string) {
  return `mailto:${to}?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
}

export function fetchMyCompany(dispatch: AppDispatch) {
  const me = useSelector(selectMyUser);
  useEffect(() => {
    switch (me?.company_type) {
      case CompanyTypeEnum.System:
        dispatch(fetchSystemCompany({ company_id: me.company! }));
        break;
      case CompanyTypeEnum.Client:
        dispatch(fetchClientCompany({ companyId: me.company! }));
        break;
      case CompanyTypeEnum.Agent:
        dispatch(fetchAgentCompanies());
        me.companies?.forEach((id) => {
          dispatch(fetchAgentCompany({ id }));
        });
        break;
    }
  }, [me, dispatch]);
}

// アクセストークンの設定・リフレッシュを自動で行うAxiosインスタンス
export const autoRefreshAxiosInstance = Axios.create();

autoRefreshAxiosInstance.interceptors.request.use((config) => {
  const access = localStorage.getItem("access")!;
  if (access) {
    config.headers.Authorization = `Bearer ${access}`;
  }
  return config;
});

autoRefreshAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const response = await new TokenApi()
        .tokenRefreshCreate({
          refresh: localStorage.getItem("refresh")!,
        })
        .then((res) => res)
        .catch(() => null);
      if (!response) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        window.open("/login", "_self");
      }
      if (response) {
        originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
        localStorage.setItem("access", response.data.access);
        return Axios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const useCampaign = (
  campaign_id: string | string[] | undefined,
  preventFetchCampaign?: boolean
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (typeof campaign_id === "string" && !preventFetchCampaign)
      dispatch(fetchCampaign({ id: campaign_id }));
  }, [campaign_id, dispatch]);

  return useSelector((state: RootState) => {
    if (typeof campaign_id === "string")
      return campaignSelectors.selectById(state, campaign_id);
    return undefined;
  });
};

export const useAgentCompany = (company_id: string | string[] | undefined) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (typeof company_id === "string")
      dispatch(fetchAgentCompany({ id: company_id }));
  }, [company_id, dispatch]);

  return useSelector((state: RootState) => {
    if (typeof company_id === "string")
      return agentCompanyDetailSelectors.selectById(state, company_id);
    return undefined;
  });
};

export function isObject(value?: any): value is object {
  const type = typeof value;
  return value != null && (type == "object" || type == "function");
}

/**
 * User Defined Type Guard!
 */
export function isClientCompanyDetail(arg: any): arg is ClientCompanyDetail {
  return isObject(arg) && "agents" in arg;
}

export function isString(value: any): value is string {
  return typeof value === "string";
}

export function isNumber(value: any): value is number {
  return typeof value === "number";
}

export function useUrlQuery(q: string): string | undefined {
  const router = useRouter();
  const query = router.query[q];
  if (typeof query === "string") return query;
  return undefined;
}

export function useCampaignId(): string | undefined {
  return useUrlQuery("campaign_id");
}

export function useAgentCompanyId(): string | undefined {
  return useUrlQuery("agent_company_id");
}

export function useClientCompanyId(): string | undefined {
  return useUrlQuery("client_company_id");
}

export function useEventId(): string | undefined {
  return useUrlQuery("event_id");
}

export const dateToStr = (
  date: string | Date | null | undefined
): string | null => {
  if (!date) return null;
  if (isString(date)) return date;
  return format(date, "yyyy-MM-dd");
};

export const sortDates = (
  dates: (string | Date | null | undefined)[]
): string[] => {
  const strDates = dates
    .map((v) => dateToStr(v))
    .filter((v): v is string => isString(v));
  return strDates.sort();
};

export const fetchContentFiles = (ids: string[]): Promise<ContentFile[]> => {
  const args = [
    undefined, // [agentCompany] agent_company
    undefined, // [createdAt] created_at
    undefined, // [fileOwner] file_owner
    undefined, // [fileSize] file_size
    undefined, // [id] id
    ids.join(","), // [idIn] id__in
    undefined, // [mimeType] mime_type
    undefined, // [name] name
    undefined, // [pdfFileSize] pdf_file_size
    undefined, // [updatedAt] updated_at
    undefined, // [uploadUser] upload_user
    undefined, // [uploadUserEmail] upload_user_email
    undefined, // [uploadUserName] upload_user_name
  ];

  if (ids.length > 0)
    return new ContentFilesApi(undefined, undefined, autoRefreshAxiosInstance)
      .contentFilesList(...args)
      .then((response) => {
        return response.data;
      });
  else return new Promise(() => []);
};

export const deleteContentFile = (id: string) => {
  return new ContentFilesApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).contentFilesDestroy(id);
};

export function formatBytes(bytes: number, decimals = 1) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const formatDateStr = (
  date: string | Date | null | undefined
): string | null => {
  if (!date) {
    return null;
  }
  if (isString(date)) {
    try {
      return format(parse(date, "yyyy-MM-dd", new Date()), "yyyy年MM月dd日");
    } catch (_error) {
      // Parse Error
      return date;
    }
  }
  return format(date, "yyyy年MM月dd日");
};

export function useAgentPermission(
  campaignId?: string,
  preventFetchCampaign?: boolean
): CampaignPermission | undefined {
  // 代理店ユーザーでキャンペーンの権限を取得する
  const [permission, setPermission] = useState<
    CampaignPermission | undefined
  >();
  const me = useSelector(selectMyUser);
  const myCompanyType = me?.company_type;
  const myCompany = useSelector(selectMyCompany);
  const hasCompanyInfo = Array.isArray(myCompany) && !!myCompany[0];
  const campaign = useCampaign(campaignId, preventFetchCampaign);
  const campaignCompanyId = campaign?.company;

  useEffect(() => {
    if (campaignId && myCompanyType && hasCompanyInfo && campaignCompanyId) {
      if (
        myCompanyType === CompanyTypeEnum.Agent &&
        campaignCompanyId &&
        hasCompanyInfo
      ) {
        const agentCompanyId = (myCompany as any).find(
          (company: any) => company?.client?.id === campaignCompanyId
        )?.id;
        if (agentCompanyId) {
          new CampaignApi(undefined, undefined, autoRefreshAxiosInstance)
            .campaignPermissionRetrieve(campaignId, agentCompanyId, undefined)
            .then((r) => {
              setPermission(r.data);
            })
            .catch((err) => {
              console.error("useAgentPermissionError");
              console.error(err);
            });
        }
      }
    }
  }, [campaignId, myCompanyType, hasCompanyInfo, campaignCompanyId]);
  return permission;
}

/**
 * normalize Array
 * @param input - Array Input
 * @param key - item Object key
 */
export function normalize(input: { [key: string]: any }[], key: string) {
  return input.reduce(
    ({ result: accResult, keys: accKeys }, current) => {
      const _key = current[key];

      return {
        result: { ...accResult, [_key]: current },
        keys: [...accKeys, `${_key}`],
      };
    },
    { result: {}, keys: [] }
  ) as { result: { [key: string]: any }; keys: string[] };
}

/**
 * addComma
 * @description Convert 12345678 -> 12, 345, 678
 * @param num
 */
export const addComma = (num: number | string) =>
  `${num ?? ""}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

export const toLocalDate = (date: Date | string) => {
  return format(new Date(date), "yyyy-MM-dd");
};

export const getMaxDate = (dates: string[]) => {
  const temp = dates.filter((item) => !!item && isValidDate(new Date(item)));

  return temp.length
    ? format(max(temp.map((item) => new Date(item))), "yyyy-MM-dd")
    : "";
};

export const getMinDate = (dates: string[]) => {
  const temp = dates.filter((item) => !!item && isValidDate(new Date(item)));

  return temp.length
    ? format(min(temp.map((item) => new Date(item))), "yyyy-MM-dd")
    : "";
};

export const exportToCsv = (filename: string, rows: any[]): void => {
  const processRow = (row: any) => {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

/**
 * getAlphabetByIndex
 * @description Convert index-> alphabet charracter
 * index 0-25 => a-z
 */
export const getAlphabetByIndex = (index: number): string =>
  (index + 10).toString(36);

/**
 * convertObjToSearchString
 * @description Convert {a: 1} -> a=1
 */
export const convertObjToSearchString = (params: {
  [key: string]: string | number;
}): string =>
  Object.entries(params)
    .reduce((acc, crt) => `${acc}&${crt[0]}=${crt[1]}`, "")
    .slice(1);

/**
 * isValidDate
 * @param date
 * @returns
 */
export function isValidDate(date: any) {
  return date instanceof Date && !isNaN(date as any);
}

/**
 * scaleDecimal
 * @param num
 * @param decimalScale
 * @returns
 */
export function scaleDecimal(num: number, decimalScale: number) {
  if (!num || num === Infinity || num === -Infinity) return 0;
  return Math.round(num * 10 ** decimalScale) / 10 ** decimalScale;
}

/**
 * toFraction
 * @param x
 */
export function toFraction(x: number) {
  if (!x)
    return {
      text: 0,
      value: [0, 1],
    };
  if (x < 0) x = -x;
  const tolerance = 0.0001;
  let num = 1;
  let den = 1;

  function iterate() {
    const R = num / den;
    if (Math.abs((R - x) / x) < tolerance) return;

    if (R < x) num++;
    else den++;
    iterate();
  }

  iterate();
  return {
    text: den === 1 ? `${num}` : `${num}/${den}`,
    value: [num, den],
  };
}
