const initialState = {
  fileData: [],
};

export function fileDataReducer(
  state = initialState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case "SAVE_FILE_DATA": {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
}
