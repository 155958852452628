import {
  TVTimeArea,
  TVTimeRatingArea,
  TVTimeRatingTarget,
  TVTimeStation,
} from "@src/openapi-generator";

export interface TVTimeReducer {
  tvTimeAreaList: TVTimeArea[];
  tvTimeStationList: TVTimeStation[];
  tvTimeRatingAreaList: TVTimeRatingArea[];
  tvTimeRatingTargetList: TVTimeRatingTarget[];
  tvTimestationOptionsByArea: any;
}

const initialState: TVTimeReducer = {
  tvTimeAreaList: [],
  tvTimeStationList: [],
  tvTimeRatingAreaList: [],
  tvTimeRatingTargetList: [],
  tvTimestationOptionsByArea: {},
};

export function tvTimeReducer(
  state = initialState,
  { type, payload }: { type: string; payload: any }
): TVTimeReducer {
  switch (type) {
    case "campaign/tvTimeArea/fulfilled": {
      return {
        ...state,
        tvTimeAreaList: payload,
      };
    }
    case "campaign/tvTimeStation/fulfilled": {
      const stationOptionsByArea: {
        [key: string]: { id: string; name: string; area: string }[];
      } = {};
      payload.forEach((station: any) => {
        if (!stationOptionsByArea[station.area])
          stationOptionsByArea[station.area] = [station];
        else stationOptionsByArea[station.area].push(station);
      });
      return {
        ...state,
        tvTimestationOptionsByArea: stationOptionsByArea,
        tvTimeStationList: payload,
      };
    }
    case "campaign/tvTimeRatingArea/fulfilled": {
      return {
        ...state,
        tvTimeRatingAreaList: payload,
      };
    }
    case "campaign/tvTimeRatingTarget/fulfilled": {
      return {
        ...state,
        tvTimeRatingTargetList: payload,
      };
    }
    default: {
      return state;
    }
  }
}
