import { createSelector } from "@reduxjs/toolkit";
import { AgentCompanyDetail, ClientCompanyDetail, CompanyTypeEnum, SystemCompany } from "@src/openapi-generator";
import { agentCompanyDetailSelectors, clientCompanyDetailSelectors, selectMyUser, systemCompanySelectors } from "../slices";

export const selectMyCompany = createSelector(
  [
    selectMyUser,
    systemCompanySelectors.selectEntities,
    clientCompanyDetailSelectors.selectEntities,
    agentCompanyDetailSelectors.selectEntities,
  ], (user, systemCompanyEntities, clientCompanyEntities, agentCompanyEntities): (
    SystemCompany | ClientCompanyDetail | (AgentCompanyDetail | undefined)[] | undefined
  ) => {
    if (!user)
      return undefined;
    if (user.company_type === CompanyTypeEnum.System && user.company)
      return systemCompanyEntities[user.company]
    if (user.company_type === CompanyTypeEnum.Client && user.company)
      return clientCompanyEntities[user.company]
    if (user.company_type === CompanyTypeEnum.Agent && user.companies)
      return user.companies.map((id) => agentCompanyEntities[id])
    return undefined;
  }
)