import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import {
  AgentCompanyApi,
  AgentUser,
  User,
  UsersApi,
} from "@src/openapi-generator";
import { logout } from "@src/redux/actions";
import { RootState } from "./rootReducer";
import { AppDispatch } from "../store";
import { autoRefreshAxiosInstance } from "@src/utils";

export const usersAdapter = createEntityAdapter<User>();

type UserState = {
  me: User | null;
  myId: string | null;
  isLoading: boolean;
  error: string | null;
};

const userInitialState = usersAdapter.getInitialState<UserState>({
  me: null,
  myId: null,
  isLoading: false,
  error: null,
});

export const fetchMe = createAsyncThunk<
  User,
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("users/fetchMeStatus", async (_) => {
  const response = await new UsersApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).usersMeRetrieve();
  return response.data;
});

export const activeUser = createAsyncThunk<
  void,
  { userId: string },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("users/activeStatus", async ({ userId }) => {
  const response = await new UsersApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).usersActiveCreate(userId);
  return response.data;
});

export const freezeUser = createAsyncThunk<
  void,
  { userId: string },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("users/freezeStatus", async ({ userId }) => {
  const response = await new UsersApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).usersFreezeCreate(userId);
  return response.data;
});

export const activeAgentUser = createAsyncThunk<
  AgentUser,
  { userId: string; agentCompanyId: string },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("users/activeAgentStatus", async ({ userId, agentCompanyId }) => {
  const response = await new AgentCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).agentCompanyActiveUserCreate(agentCompanyId, { user_id: userId });
  return response.data;
});

export const freezeAgentUser = createAsyncThunk<
  AgentUser,
  { userId: string; agentCompanyId: string },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("users/freezeAgentStatus", async ({ userId, agentCompanyId }) => {
  const response = await new AgentCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).agentCompanyFreezeUserCreate(agentCompanyId, { user_id: userId });
  return response.data;
});

const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    /*
    loadAuthInfo(state){
      state = getAuthInfoFromLocalStorage();
      return state
    },
    */
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMe.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });

    builder.addCase(fetchMe.fulfilled, (state, action) => {
      state.me = action.payload;
      state.myId = action.payload.id || null;
      state.isLoading = false;
      state.error = null;
      usersAdapter.upsertOne(state, action.payload);
      return state;
    });

    builder.addCase(fetchMe.rejected, (state, action) => {
      state.me = null;
      state.isLoading = false;
      state.error = action.error.message || null;
      return state;
    });

    builder.addCase(logout, (state, _action) => {
      usersAdapter.removeAll(state);
      state.me = null;
      state.error = null;
      state.myId = null;
      return state;
    });
  },
});

export default userSlice.reducer;
// export const {} = userSlice.actions

export const usersSelectors = usersAdapter.getSelectors<RootState>(
  (state) => state.user
);

export const selectMyUser = createSelector(
  [(state: RootState) => state, (state: RootState) => state.user.myId],
  (state, myId) => {
    if (myId == null) return undefined;
    return usersSelectors.selectById(state, myId);
  }
);
