import { TrafficMaster } from "./../../../openapi-generator/model/traffic-master";

export interface TrafficReducer {
  trafficMasterList: TrafficMaster[];
  areaOptions: string[];
  companyOptionsByArea: any;
  lineOptionsByType: any;
  typeOptionsByCompany: any;
}
const initialState: TrafficReducer = {
  trafficMasterList: [],
  areaOptions: [],
  companyOptionsByArea: {},
  lineOptionsByType: {},
  typeOptionsByCompany: {},
};

export function trafficReducer(
  state = initialState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case "campaign/trafficMasterList/fulfilled": {
      const typeOptionsByCompany = payload.reduce(
        (acc: any, crt: any) => ({
          ...acc,
          [crt.company]: new Set([...(acc[crt.company] || []), crt.type]),
        }),
        {}
      );
      const lineOptionsByType = payload.reduce(
        (acc: any, crt: any) => ({
          ...acc,
          [crt.type]: new Set([...(acc[crt.type] || []), crt.line_set]),
        }),
        {}
      );
      const companyOptionsByArea = payload.reduce(
        (acc: any, crt: any) => ({
          ...acc,
          [crt.area]: new Set([...(acc[crt.area] || []), crt.company]),
        }),
        {}
      );
      const areaOptions = [...new Set(payload.map((item: any) => item.area))];

      return {
        ...state,
        areaOptions,
        radioTimeAreaList: payload,
        typeOptionsByCompany,
        lineOptionsByType,
        companyOptionsByArea,
      };
    }
    default: {
      return state;
    }
  }
}
