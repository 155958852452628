import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { AgentCompany, AgentCompanyAdd, AgentCompanyApi, AgentCompanyDetail, AgentCompanyMaster, AgentMasterApi } from '@src/openapi-generator'
import { RootState } from "./rootReducer";
import { AppDispatch } from "../store";
import { autoRefreshAxiosInstance } from "@src/utils";
import { clearAll } from "../actions";


// Adapter
const agentCompanyAdapter = createEntityAdapter<AgentCompany>()
const agentCompanyDetailAdapter = createEntityAdapter<AgentCompanyDetail>()
const agentCompanyMasterAdapter = createEntityAdapter<AgentCompanyMaster>()

// State
const agentCompanyInitialState = agentCompanyAdapter.getInitialState()
const agentCompanyDetailInitialState = agentCompanyDetailAdapter.getInitialState()
const agentCompanyMasterInitialState = agentCompanyMasterAdapter.getInitialState()

type AgentCompanyState = {
  agentCompany: typeof agentCompanyInitialState,
  agentCompanyDetail: typeof agentCompanyDetailInitialState,
  agentCompanyMaster: typeof agentCompanyMasterInitialState,
  isLoading: boolean,
  error: string | null
}

const agentInitialState: AgentCompanyState = {
  agentCompany: agentCompanyInitialState,
  agentCompanyDetail: agentCompanyDetailInitialState,
  agentCompanyMaster: agentCompanyMasterInitialState,
  isLoading: false,
  error: null

}

type ThunkAPIConfig = {
  state: RootState,
  dispatch: AppDispatch,
  // rejectValue: ,
  // extra: ,
}

// Thunk
/*
export const fetchAgentCompanies = createAsyncThunk<void, void, ThunkAPIConfig>(
  'agentCompany/fetchAll',
  async (_arg, _thunkAPI) => {

  }
)
*/

export const fetchAgentCompanies = createAsyncThunk<
  AgentCompany[],
  void,
  ThunkAPIConfig
>(
  'agentCompany/fetchAll',
  async (_arg, _thunkAPI) => {
    const api = new AgentCompanyApi(undefined, undefined, autoRefreshAxiosInstance)
    const response = await api.agentCompanyList()
    return response.data
  }
)

export const fetchAgentCompany = createAsyncThunk<
  AgentCompanyDetail,
  {id: string},
  ThunkAPIConfig
>(
  'agentCompany/fetchById',
  async ({id}, _thunkAPI) => {
    const api = new AgentCompanyApi(undefined, undefined, autoRefreshAxiosInstance)
    const response = await api.agentCompanyRetrieve(id)
    return response.data
  }
)

export const addAgentCompany = createAsyncThunk<
  AgentCompanyAdd,
  AgentCompanyAdd,
  ThunkAPIConfig
>(
  'agentCompany/addCompanyStatus',
  async (arg, thunkAPI) => {
    const api = new AgentCompanyApi(undefined, undefined, autoRefreshAxiosInstance)
    try{
      const response = await api.agentCompanyCreate(arg)
      return response.data
    } catch(err){
      console.log(err)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const deleteAgentCompany = createAsyncThunk<
  void,
  {id: string},
  ThunkAPIConfig
>(
  'agentCompany/deleteCompanyStatus',
  async ({id}, _thunkAPI) => {
    const api = new AgentCompanyApi(undefined, undefined, autoRefreshAxiosInstance)
    const response = await api.agentCompanyDestroy(id)
    return response.data
  }
)

// ユーザー追加・削除

// 代理店マスターデータ
export const fetchAgentCompanyMaster = createAsyncThunk<
  AgentCompanyMaster[],
  void,
  ThunkAPIConfig
>(
  'agentCompanyMaster/fetchAll',
  async (_arg, _thunkAPI) => {
    const masterApi = new AgentMasterApi(undefined, undefined, autoRefreshAxiosInstance)
    const response = await masterApi.agentMasterList()
    return response.data
  }
)




// Slice
const agentCompanySlice = createSlice({
  name: 'agentCompany',
  initialState: agentInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAgentCompanies.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    builder.addCase(fetchAgentCompanies.fulfilled, (state, action) => {
      agentCompanyAdapter.upsertMany(state.agentCompany, action.payload)
      state.isLoading = false;
      state.error = null;
      return state;
    })
    builder.addCase(fetchAgentCompanies.rejected, (state, action) => {
      state.isLoading = false;
      console.log('rejected')
      console.log(action)
      state.error = 'error';
      return state;
    })

    builder.addCase(fetchAgentCompany.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    builder.addCase(fetchAgentCompany.fulfilled, (state, action) => {
      agentCompanyDetailAdapter.upsertOne(state.agentCompanyDetail, action.payload)
      state.isLoading = false;
      state.error = null;
      return state;
    })
    builder.addCase(fetchAgentCompany.rejected, (state, action) => {
      state.isLoading = false;
      console.log('rejected')
      console.log(action)
      state.error = 'error';
      return state;
    })

    builder.addCase(deleteAgentCompany.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    builder.addCase(deleteAgentCompany.fulfilled, (state, action) => {
      agentCompanyAdapter.removeOne(state.agentCompany, action.meta.arg.id)
      agentCompanyDetailAdapter.removeOne(state.agentCompanyDetail, action.meta.arg.id)
      state.isLoading = false;
      state.error = null;
      return state;
    })
    builder.addCase(deleteAgentCompany.rejected, (state, action) => {
      state.isLoading = false;
      console.log('rejected')
      console.log(action)
      state.error = 'error';
      return state;
    })

    builder.addCase(fetchAgentCompanyMaster.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    builder.addCase(fetchAgentCompanyMaster.fulfilled, (state, action) => {
      agentCompanyMasterAdapter.upsertMany(state.agentCompanyMaster, action.payload)
      state.isLoading = false;
      state.error = null;
      return state;
    })
    builder.addCase(fetchAgentCompanyMaster.rejected, (state, action) => {
      state.isLoading = false;
      console.log('rejected')
      console.log(action)
      state.error = 'error';
      return state;
    })

    builder.addCase(addAgentCompany.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    builder.addCase(addAgentCompany.fulfilled, (state, _action) => {
      state.isLoading = false;
      state.error = null;
      return state;
    })
    builder.addCase(addAgentCompany.rejected, (state, action) => {
      state.isLoading = false;
      console.log('rejected')
      console.log(action)
      state.error = 'error';
      return state;
    })
    

    builder.addCase(clearAll, (state) => {
      agentCompanyAdapter.removeAll(state.agentCompany)
      agentCompanyDetailAdapter.removeAll(state.agentCompanyDetail)
      state.isLoading = false;
      state.error = null;
      return state;
    })
  }
})

// Reducer
export default agentCompanySlice.reducer;

// Action
// export const {} = agentCompanySlice.actions;

// Selector

export const agentCompanySelectors = agentCompanyAdapter.getSelectors<RootState>(
  (state) => state.agentCompany.agentCompany
)
export const agentCompanyDetailSelectors = agentCompanyDetailAdapter.getSelectors<RootState>(
  (state) => state.agentCompany.agentCompanyDetail
)
export const agentCompanyMasterSelectors = agentCompanyMasterAdapter.getSelectors<RootState>(
  (state) => state.agentCompany.agentCompanyMaster
)

export const agentCompanyFilterByClientIdSelector = (clientId: string) => createSelector(
  [
    (state: RootState) => agentCompanySelectors.selectAll(state),
  ], (agentCompanies) => {
    return agentCompanies.filter(
      (agentCompany) => agentCompany.client?.id === clientId
    )
  }
)