// サイズ
export const sizeOptions: { name: string }[] = [
  { name: "全" },
  { name: "1/2" },
  { name: "1/3" },
  { name: "1/4" },
  { name: "1/6" },
  { name: "小型広告" },
  { name: "突出" },
  { name: "その他" },
];
