import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  SimpleJWTRefreshResponse,
  SimpleJWTResponse,
  TokenApi,
  UsersApi,
} from "@src/openapi-generator";
import { autoRefreshAxiosInstance } from "@src/utils";
import { logout } from "../actions";
import { RootState } from "./rootReducer";

type AuthInfoState = {
  access: string | null;
  refresh: string | null;
  isLogin: boolean;
  isLoading: boolean;
  error: string | null;
};

function getAuthInfoFromLocalStorage(): AuthInfoState {
  const access = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");
  if (access && refresh) {
    return {
      access: access,
      refresh: refresh,
      isLogin: true,
      isLoading: false,
      error: null,
    };
  }
  return {
    access: null,
    refresh: null,
    isLogin: false,
    isLoading: false,
    error: null,
  };
}

const authInfoInitialState: AuthInfoState = {
  access: null,
  refresh: null,
  isLogin: false,
  isLoading: true,
  error: null,
};

export const login = createAsyncThunk(
  "auth/loginStatus",
  async (args: { email: string; password: string }) => {
    const response = await new TokenApi().tokenCreate({
      email: args.email,
      password: args.password,
    });
    return response.data;
  }
);

export const refreshAccessToken = createAsyncThunk<
  SimpleJWTRefreshResponse,
  void,
  {
    state: RootState;
  }
>("auth/refreshStatus", async (_, { getState }) => {
  const refresh = getState().auth.refresh;
  const response = await new TokenApi().tokenRefreshCreate({
    refresh: refresh!,
  });
  return response.data;
});

export const impersonate = createAsyncThunk<
  SimpleJWTResponse,
  { userId: string },
  {
    state: RootState;
  }
>("auth/impersonateStatus", async ({ userId }) => {
  const response = await new UsersApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).usersImpersonateCreate(userId);
  return response.data;
});

const authInfoSlice = createSlice({
  name: "authInfo",
  initialState: authInfoInitialState,
  reducers: {
    loadAuthInfo(state) {
      state = getAuthInfoFromLocalStorage();
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.access = null;
      state.refresh = null;
      state.isLoading = true;
      state.isLogin = false;
      state.error = null;
      return state;
    });
    builder.addCase(logout, (state) => {
      state = {
        access: null,
        refresh: null,
        isLogin: false,
        isLoading: false,
        error: null,
      };
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return state;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      localStorage.setItem("access", state.access);
      localStorage.setItem("refresh", state.refresh);
      state.isLoading = false;
      state.isLogin = true;
      state.error = null;
      return state;
    });

    builder.addCase(login.rejected, (state) => {
      state.access = null;
      state.refresh = null;
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      state.isLoading = false;
      state.isLogin = false;
      state.error = "error";
      return state;
    });

    builder.addCase(refreshAccessToken.pending, (state) => {
      state.access = null;
      localStorage.removeItem("access");
      state.isLoading = true;
      state.error = null;
      return state;
    });

    builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
      state.access = action.payload.access;
      localStorage.setItem("access", state.access);
      state.isLoading = false;
      state.isLogin = true;
      state.error = null;
      return state;
    });

    builder.addCase(refreshAccessToken.rejected, (state) => {
      state.access = null;
      state.refresh = null;
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      state.isLoading = false;
      state.isLogin = false;
      state.error = "error";
      return state;
    });

    builder.addCase(impersonate.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });

    builder.addCase(impersonate.fulfilled, (state, action) => {
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.setItem("access", state.access);
      localStorage.setItem("refresh", state.refresh);
      state.isLoading = false;
      state.isLogin = true;
      state.error = null;
      return state;
    });

    builder.addCase(impersonate.rejected, (state) => {
      state.isLoading = false;
      state.error = "error";
      return state;
    });
  },
});

export default authInfoSlice.reducer;
export const { loadAuthInfo } = authInfoSlice.actions;
