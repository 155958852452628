import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  ClientCompanyApi,
  SystemUserAdd,
  ClientCompanyDetail,
  ClientCompany,
  ClientCompanyRegister,
  ClientUserAdd,
} from "@src/openapi-generator";
import { RootState } from "./rootReducer";
import { AppDispatch } from "../store";
import { ClientUserUpdateRole } from "@src/openapi-generator/model/client-user-update-role";
import { ClientUserDelete } from "@src/openapi-generator/model/client-user-delete";
import { autoRefreshAxiosInstance } from "@src/utils";
import { clearAll } from "../actions";

export const clientCompanyAdapter = createEntityAdapter<ClientCompany>();
export const clientCompanyDetailAdapter = createEntityAdapter<ClientCompanyDetail>();

const clientCompanyInitialState = clientCompanyAdapter.getInitialState();
const clientCompanyDetailInitialState = clientCompanyDetailAdapter.getInitialState();

export type ClientCompanyState = {
  clientCompany: typeof clientCompanyInitialState;
  clientCompanyDetail: typeof clientCompanyDetailInitialState;
  myCompanyId: string | null;
  add_system_user: SystemUserAdd | null;
  isLoading: boolean;
  error: string | null;
};

const clientInitialState: ClientCompanyState = {
  clientCompany: clientCompanyInitialState,
  clientCompanyDetail: clientCompanyDetailInitialState,
  myCompanyId: null,
  add_system_user: null,
  isLoading: false,
  error: null,
};

export const fetchClientCompanies = createAsyncThunk<
  ClientCompany[],
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("clientCompany/fetchCompaniesStatus", async (_) => {
  const response = await new ClientCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).clientCompanyList();
  return response.data;
});

export const fetchClientCompany = createAsyncThunk<
  ClientCompanyDetail,
  { companyId: string },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("clientCompany/fetchCompanyStatus", async ({ companyId }) => {
  const response = await new ClientCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).clientCompanyRetrieve(companyId);
  return response.data;
});

export const addClientCompany = createAsyncThunk<
  ClientCompanyRegister,
  ClientCompanyRegister,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("clientCompany/addCompanyStatus", async (data) => {
  const response = await new ClientCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).clientCompanyCreate(data);
  return response.data;
});

export const deleteClientCompany = createAsyncThunk<
  void,
  { companyId: string },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("clientCompany/deleteCompanyStatus", async ({ companyId }) => {
  const response = await new ClientCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).clientCompanyDestroy(companyId);
  return response.data;
});

export const addClientUser = createAsyncThunk<
  ClientUserAdd,
  { companyId: string; data: ClientUserAdd },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("clientCompany/addUserStatus", async ({ companyId, data }) => {
  const response = await new ClientCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).clientCompanyAddUserCreate(companyId, data);
  return response.data;
});

export const updateClientUserRole = createAsyncThunk<
  ClientUserUpdateRole,
  { companyId: string; data: ClientUserUpdateRole },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("clientCompany/updateUserRoleStatus", async ({ companyId, data }) => {
  const response = await new ClientCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).clientCompanyUpdateUserRoleCreate(companyId, data);
  return response.data;
});

export const deleteClientUser = createAsyncThunk<
  ClientUserDelete,
  { companyId: string; data: ClientUserDelete },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("clientCompany/deleteUserStatus", async ({ companyId, data }) => {
  const response = await new ClientCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).clientCompanyDeleteUserCreate(companyId, data);
  return response.data;
});

const clientCompanySlice = createSlice({
  name: "clientCompany",
  initialState: clientInitialState,
  reducers: {
    /*
    loadAuthInfo(state){
      state = getAuthInfoFromLocalStorage();
      return state
    },
    */
  },
  extraReducers: (builder) => {
    // fetchClientCompanies
    builder.addCase(fetchClientCompanies.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });

    builder.addCase(fetchClientCompanies.fulfilled, (state, action) => {
      clientCompanyAdapter.upsertMany(state.clientCompany, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });

    builder.addCase(fetchClientCompanies.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || null;
      return state;
    });

    // fetchClientCompany
    builder.addCase(fetchClientCompany.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });

    builder.addCase(fetchClientCompany.fulfilled, (state, action) => {
      clientCompanyDetailAdapter.upsertOne(
        state.clientCompanyDetail,
        action.payload
      );
      state.isLoading = false;
      state.error = null;
      return state;
    });

    builder.addCase(fetchClientCompany.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || null;
      return state;
    });

    builder.addCase(deleteClientCompany.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });

    builder.addCase(deleteClientCompany.fulfilled, (state, action) => {
      clientCompanyAdapter.removeOne(
        state.clientCompany,
        action.meta.arg.companyId
      );
      clientCompanyDetailAdapter.removeOne(
        state.clientCompanyDetail,
        action.meta.arg.companyId
      );
      state.isLoading = false;
      state.error = null;
      return state;
    });

    builder.addCase(deleteClientCompany.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || null;
      return state;
    });

    builder.addCase(clearAll, (state) => {
      clientCompanyAdapter.removeAll(state.clientCompany);
      clientCompanyDetailAdapter.removeAll(state.clientCompanyDetail);
      state.isLoading = false;
      state.error = null;
      return state;
    });
  },
});

export default clientCompanySlice.reducer;
// export const {} = userSlice.actions

export const clientCompanySelectors = clientCompanyAdapter.getSelectors<RootState>(
  (state) => state.clientCompany.clientCompany
);

export const clientCompanyDetailSelectors = clientCompanyDetailAdapter.getSelectors<RootState>(
  (state) => state.clientCompany.clientCompanyDetail
);
