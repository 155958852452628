import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import { ContentFile, ContentFilesApi } from "@src/openapi-generator"
import { RootState } from "./rootReducer";
import { AppDispatch } from "../store";
import { autoRefreshAxiosInstance } from "@src/utils";

// Adapter
const contentFileAdapter = createEntityAdapter<ContentFile>()

// State
const contentFileInitialState = contentFileAdapter.getInitialState()

type State = {
  contentFile: typeof contentFileInitialState,
  isLoading: boolean,
  error: string | null
}

const initialState: State = {
  contentFile: contentFileInitialState,
  isLoading: false,
  error: null
}

// Thunk
type ThunkAPIConfig = {
  state: RootState,
  dispatch: AppDispatch,
  // rejectValue: ,
  // extra: ,
}

export const fetchContentFileAll = createAsyncThunk<
  ContentFile[],
  void,
  ThunkAPIConfig
>(
  'contentFile/fetchAll',
  async (_arg, _thunkAPI) => {
    const api = new ContentFilesApi(undefined, undefined, autoRefreshAxiosInstance)
    const response = await api.contentFilesList()
    return response.data
  }
)

export const fetchContentFile = createAsyncThunk<
  ContentFile,
  { id: string },
  ThunkAPIConfig
>(
  'contentFile/fetchById',
  async ({id}, _thunkAPI) => {
    const api = new ContentFilesApi(undefined, undefined, autoRefreshAxiosInstance)
    const response = await api.contentFilesRetrieve(id)
    return response.data
  }
)

// Slice
const slice = createSlice({
  name: 'contentFile',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContentFileAll.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state
    })
    builder.addCase(fetchContentFileAll.fulfilled, (state, action) => {
      contentFileAdapter.upsertMany(state.contentFile, action.payload)
      state.isLoading = false;
      state.error = null;
      return state
    })
    builder.addCase(fetchContentFileAll.rejected, (state) => {
      state.isLoading = false;
      state.error = "error";
      return state
    })

    builder.addCase(fetchContentFile.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state
    })
    builder.addCase(fetchContentFile.fulfilled, (state, action) => {
      contentFileAdapter.upsertOne(state.contentFile, action.payload)
      state.isLoading = false;
      state.error = null;
      return state
    })
    builder.addCase(fetchContentFile.rejected, (state) => {
      state.isLoading = false;
      state.error = "error";
      return state
    })
  }
})

// Reducer
export default slice.reducer;

// Action
// export const {} = slice.actions;

// Selector
export const contentFileSelectors = contentFileAdapter.getSelectors<RootState>(
  (state) => state.contentFile.contentFile
)



