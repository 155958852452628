import { RadioTimeArea, RadioTimeStation } from "@src/openapi-generator";

export interface RadioTimeReducer {
  radioTimeAreaList: RadioTimeArea[];
  radioTimeStationList: RadioTimeStation[];
  stationOptionsByAreaType: {
    [key: string]: {
      id: string;
      name: string;
      area: string;
      type: string;
    }[];
  };
}
const initialState: RadioTimeReducer = {
  radioTimeAreaList: [],
  radioTimeStationList: [],
  stationOptionsByAreaType: {},
};

export function radioTimeReducer(
  state = initialState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case "campaign/radioTimeArea/fulfilled": {
      return {
        ...state,
        radioTimeAreaList: payload,
      };
    }
    case "campaign/radioTimeStation/fulfilled": {
      const stationOptionsByAreaType = payload?.reduce(
        (acc: any, crt: any) => ({
          ...acc,
          [`${crt.type}_${crt.area}`]: [
            ...(acc[`${crt.type}_${crt.area}`] || []),
            crt,
          ],
        }),
        {}
      );
      return {
        ...state,
        radioTimeStationList: payload,
        stationOptionsByAreaType,
      };
    }
    default: {
      return state;
    }
  }
}
