import { WebMediaType } from "@src/openapi-generator";

export interface WebReducer {
  webMasterList: WebMediaType[];
  isLoading: boolean;
}
const initialState: WebReducer = {
  isLoading: true,
  webMasterList: [],
};

export function webReducer(
  state = initialState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case "campaign/webMediaTypes/fulfilled": {
      return {
        ...state,
        isLoading: false,
        webMasterList: payload,
      };
    }
    default: {
      return state;
    }
  }
}
