// 区分
export const sectionOptions: { name: string }[] = [
  { name: "記事下" },
  { name: "記事中" },
  { name: "TV表札" },
  { name: "夕刊TV" },
  { name: "純広３ｄ" },
  { name: "純広全頁" },
  { name: "純広二連版" },
  { name: "ペイド３ｄ" },
  { name: "ペイド全頁" },
];
