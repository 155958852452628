const initialState = {
  magazineNames: [],
  magazineInfoByName: {},
  magazineMater: [],
  isLoading: true,
};

export function magazineReducer(
  state = initialState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case "SAVE_MAGAZINE_INFO": {
      return {
        ...state,
        ...payload,
      };
    }
    case "campaign/magazineMasterList/fulfilled": {
      const data = normalize(payload);
      return {
        ...state,
        ...data,
        magazineMater: payload,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
}
function normalize(dataSource: any[]) {
  const magazineNames = [];
  const magazineInfoByName: { [key: string]: any } = {};
  let i;
  for (i = 0; i < dataSource.length; i += 1) {
    const item = dataSource[i];
    magazineNames.push(item.name);
    magazineInfoByName[item.name] = item;
  }

  return {
    magazineNames,
    magazineInfoByName,
  };
}
