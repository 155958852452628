import { RadioSpotStation } from "@src/openapi-generator";
import { RadioSpotArea } from "./../../../openapi-generator/model/radio-spot-area";
export interface RadioSpotReducer {
  radioSpotAreaList: RadioSpotArea[];
  radioSpotStationList: RadioSpotStation[];
}
const initialState: RadioSpotReducer = {
  radioSpotAreaList: [],
  radioSpotStationList: [],
};

export function radioSpotReducer(
  state = initialState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case "campaign/radioSpotArea/fulfilled": {
      return {
        ...state,
        radioSpotAreaList: payload,
      };
    }
    case "campaign/radioSpotStation/fulfilled": {
      return {
        ...state,
        radioSpotStationList: payload,
      };
    }
    default: {
      return state;
    }
  }
}
