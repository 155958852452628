import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  SystemCompanyApi,
  SystemCompany,
  SystemUserAdd,
  SystemRoleEnum,
  SystemUserDelete,
  SystemUserUpdateRole,
} from "@src/openapi-generator";
import { RootState } from "./rootReducer";
import { AppDispatch } from "../store";
import { autoRefreshAxiosInstance } from "@src/utils";
import { clearAll } from "../actions";

export const systemCompanyAdapter = createEntityAdapter<SystemCompany>();
const systemCompanyInitialState = systemCompanyAdapter.getInitialState();

type SystemCompanyState = {
  systemCompany: typeof systemCompanyInitialState;
  system_company: SystemCompany | null;
  add_system_user: SystemUserAdd | null;
  isLoading: boolean;
  error: string | null;
};

const initialState: SystemCompanyState = {
  systemCompany: systemCompanyInitialState,
  system_company: null,
  add_system_user: null,
  isLoading: false,
  error: null,
};

export const fetchSystemCompany = createAsyncThunk<
  SystemCompany,
  { company_id: string },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("systemCompany/fetchStatus", async ({ company_id }) => {
  const response = await new SystemCompanyApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  ).systemCompanyRetrieve(company_id);
  return response.data;
});

export const addSystemUser = createAsyncThunk<
  SystemUserAdd,
  { email: string; name: string; role: SystemRoleEnum },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "systemCompany/addUserStatus",
  async ({ email, name, role }, { getState, rejectWithValue }) => {
    const company_id = getState().systemCompany.system_company?.id;
    if (!company_id) return rejectWithValue({ message: "no company_id" });
    const response = await new SystemCompanyApi(
      undefined,
      undefined,
      autoRefreshAxiosInstance
    ).systemCompanyAddUserCreate(company_id, { email, name, role });
    return response.data;
  }
);

export const deleteSystemUser = createAsyncThunk<
  SystemUserDelete,
  { user_id: string },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "systemCompany/deleteUserStatus",
  async ({ user_id }, { getState, rejectWithValue }) => {
    const company_id = getState().systemCompany.system_company?.id;
    if (!company_id) return rejectWithValue({ message: "no company_id" });
    const response = await new SystemCompanyApi(
      undefined,
      undefined,
      autoRefreshAxiosInstance
    ).systemCompanyDeleteUserCreate(company_id, { user_id });
    return response.data;
  }
);

export const updateSystemUserRole = createAsyncThunk<
  SystemUserUpdateRole,
  { user_id: string; role: SystemRoleEnum },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "systemCompany/updateUserRoleStatus",
  async ({ user_id, role }, { getState, rejectWithValue }) => {
    const company_id = getState().systemCompany.system_company?.id;
    if (!company_id) return rejectWithValue({ message: "no company_id" });
    const response = await new SystemCompanyApi(
      undefined,
      undefined,
      autoRefreshAxiosInstance
    ).systemCompanyUpdateUserRoleCreate(company_id, { user_id, role });
    return response.data;
  }
);

const systemCompanySlice = createSlice({
  name: "systemCompany",
  initialState: initialState,
  reducers: {
    /*
    loadAuthInfo(state){
      state = getAuthInfoFromLocalStorage();
      return state
    },
    */
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSystemCompany.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });

    builder.addCase(fetchSystemCompany.fulfilled, (state, action) => {
      systemCompanyAdapter.upsertOne(state.systemCompany, action.payload);
      state.system_company = action.payload;
      state.isLoading = false;
      state.error = null;
      return state;
    });

    builder.addCase(fetchSystemCompany.rejected, (state, action) => {
      state.system_company = null;
      state.isLoading = false;
      state.error = action.error.message || null;
      return state;
    });

    builder.addCase(addSystemUser.pending, (state) => {
      state.add_system_user = null;
      state.isLoading = true;
      state.error = null;
      return state;
    });

    builder.addCase(addSystemUser.fulfilled, (state, action) => {
      state.add_system_user = action.payload;
      state.isLoading = false;
      state.error = null;
      return state;
    });

    builder.addCase(addSystemUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || null;
      return state;
    });

    builder.addCase(clearAll, (state) => {
      systemCompanyAdapter.removeAll(state.systemCompany);
      state.system_company = null;
      state.isLoading = false;
      state.error = null;
      return state;
    });
  },
});

export default systemCompanySlice.reducer;
// export const {} = userSlice.actions

export const systemCompanySelectors = systemCompanyAdapter.getSelectors<RootState>(
  (state) => state.systemCompany.systemCompany
);
