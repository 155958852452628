import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  Campaign,
  CampaignApi,
  OrientationFile,
  PatchedCampaign,
} from "@src/openapi-generator";
import { RootState } from "./rootReducer";
import { AppDispatch } from "../store";
import { autoRefreshAxiosInstance } from "@src/utils";
import { clearAll } from "../actions";

// Adapter
const campaignAdapter = createEntityAdapter<Campaign>();

// State
const campaignInitialState = campaignAdapter.getInitialState();

type State = {
  campaign: typeof campaignInitialState;
  isLoading: boolean;
  error: string | null;
};

const initialState: State = {
  campaign: campaignInitialState,
  isLoading: false,
  error: null,
};

// Thunk
type ThunkAPIConfig = {
  state: RootState;
  dispatch: AppDispatch;
  // rejectValue: ,
  // extra: ,
};

export const fetchCampaignAll = createAsyncThunk<
  Campaign[],
  void,
  ThunkAPIConfig
>("campaign/fetchAll", async (_arg, _thunkAPI) => {
  const campaignApi = new CampaignApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await campaignApi.campaignList();
  return response.data;
});

export const fetchCampaign = createAsyncThunk<
  Campaign,
  { id: string; preprocessor?: (campaignData: Campaign) => any },
  ThunkAPIConfig
>("campaign/fetchById", async ({ id, preprocessor = null }, _thunkAPI) => {
  const campaignApi = new CampaignApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await campaignApi.campaignRetrieve(id);

  const { data } = response;
  let preprocessedData = {};

  if (preprocessor) preprocessedData = preprocessor(data);

  return { ...response.data, ...preprocessedData } as any;
});

export const createCampaign = createAsyncThunk<
  Campaign,
  { name: string },
  ThunkAPIConfig
>("campaign/create", async ({ name }, _thunkAPI) => {
  const campaignApi = new CampaignApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await campaignApi.campaignCreate({ name });
  return response.data;
});

export const updateCampaign = createAsyncThunk<
  Campaign,
  { id: string; data: PatchedCampaign },
  ThunkAPIConfig
>("campaign/update", async ({ id, data }, _thunkAPI) => {
  const campaignApi = new CampaignApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await campaignApi.campaignPartialUpdate(id, data);
  return response.data;
});

export const deleteCampaign = createAsyncThunk<
  void,
  { id: string },
  ThunkAPIConfig
>("campaign/delete", async ({ id }, _thunkAPI) => {
  const campaignApi = new CampaignApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await campaignApi.campaignDestroy(id);
  return response.data;
});

export const addOrientationFile = createAsyncThunk<
  OrientationFile,
  { id: string; data: OrientationFile },
  ThunkAPIConfig
>("campaign/addOrientationFile", async ({ id, data }, _thunkAPI) => {
  const campaignApi = new CampaignApi(
    undefined,
    undefined,
    autoRefreshAxiosInstance
  );
  const response = await campaignApi.campaignMagazineReportFilesCreate(
    id,
    data
  );
  return response.data;
});

// Slice
const slice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCampaignAll.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(fetchCampaignAll.fulfilled, (state, action) => {
      campaignAdapter.upsertMany(state.campaign, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(fetchCampaignAll.rejected, (state) => {
      state.isLoading = false;
      state.error = "error";
      return state;
    });

    builder.addCase(fetchCampaign.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(fetchCampaign.fulfilled, (state, action) => {
      campaignAdapter.upsertOne(state.campaign, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(fetchCampaign.rejected, (state) => {
      state.isLoading = false;
      state.error = "error";
      return state;
    });

    builder.addCase(deleteCampaign.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(deleteCampaign.fulfilled, (state, action) => {
      campaignAdapter.removeOne(state.campaign, action.meta.arg.id);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(deleteCampaign.rejected, (state) => {
      state.isLoading = false;
      state.error = "error";
      return state;
    });

    builder.addCase(updateCampaign.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(updateCampaign.fulfilled, (state, action) => {
      campaignAdapter.upsertOne(state.campaign, action.payload);
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(updateCampaign.rejected, (state) => {
      state.isLoading = false;
      state.error = "error";
      return state;
    });

    builder.addCase(addOrientationFile.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      return state;
    });
    builder.addCase(addOrientationFile.fulfilled, (state, _action) => {
      /*
      const { id, data } = action.meta.arg;
      campaignAdapter.updateOne(state.campaign, {id: id, changes: {
        orientation_files: [data.contentfile]
      }})
      */
      state.isLoading = false;
      state.error = null;
      return state;
    });
    builder.addCase(addOrientationFile.rejected, (state) => {
      state.isLoading = false;
      state.error = "error";
      return state;
    });

    builder.addCase(clearAll, (state) => {
      campaignAdapter.removeAll(state.campaign);
      state.isLoading = false;
      state.error = null;
      return state;
    });
  },
});

// Reducer
export default slice.reducer;

// Action
// export const {} = slice.actions;

// Selector
export const campaignSelectors = campaignAdapter.getSelectors<RootState>(
  (state) => state.campaign.campaign
);
